import { Input, InputProps } from './input';
import { Label } from './label';
import { Textarea, TextareaProps } from './textarea';
import { cn } from '@/lib/utils';
import React from 'react';

const FloatingTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <Textarea
        rows={2}
        placeholder=' '
        className={cn(
          'peer max-h-[160px] min-h-[80px] resize-none pt-6 hover:resize-y',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

type TextareaWithInternalLabelProps = TextareaProps & { label?: string };

const FloatingInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <Input
        placeholder=' '
        className={cn('peer h-[56px] pt-6', className)}
        ref={ref}
        {...props}
      />
    );
  },
);
FloatingInput.displayName = 'FloatingInput';

const FloatingLabel = React.forwardRef<
  React.ElementRef<typeof Label>,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
  return (
    <Label
      className={cn(
        'peer-focus:secondary peer-focus:dark:secondary dark:bg-background absolute start-2 top-[1.3rem] z-10 origin-[0] -translate-y-4 scale-[.7] transform overflow-hidden whitespace-nowrap bg-transparent px-2 text-base font-normal text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-[1.3rem] peer-focus:-translate-y-4 peer-focus:scale-[.7] peer-focus:px-2 lg:pr-0 xl:pr-2 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
FloatingLabel.displayName = 'FloatingLabel';

type InputWithInternalLabelProps = InputProps & { label?: string };

const InputWithInternalLabel = React.forwardRef<
  React.ElementRef<typeof FloatingInput>,
  React.PropsWithoutRef<InputWithInternalLabelProps> & { textarea?: boolean }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ id, label, textarea, ...props }, ref) => {
  return (
    <div className={cn('relative', props.className)}>
      <FloatingInput ref={ref} id={id} {...props} />
      <FloatingLabel htmlFor={id}>{label}</FloatingLabel>
    </div>
  );
});

const TextareaWithInternalLabel = React.forwardRef<
  React.ElementRef<typeof FloatingTextarea>,
  React.PropsWithoutRef<TextareaWithInternalLabelProps>
>(({ id, label, ...props }, ref) => {
  return (
    <div className={cn('relative', props.className)}>
      <FloatingTextarea ref={ref} id={id} {...props} />
      <FloatingLabel htmlFor={id}>{label}</FloatingLabel>
    </div>
  );
});

InputWithInternalLabel.displayName = 'InputWithInternalLabel';

export { FloatingInput, InputWithInternalLabel, TextareaWithInternalLabel };
