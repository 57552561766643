import { cn } from '@/lib/utils';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@repo/ui/components/ui/form';
import { Input } from '@repo/ui/components/ui/input';
import { InputWithInternalLabel } from '@repo/ui/components/ui/input-floating';
import { useTranslations } from 'next-intl';
import {
  type ControllerRenderProps,
  type FieldValues,
  type Path,
  type UseFormReturn,
} from 'react-hook-form';

type InputProps<T extends FieldValues> = {
  form: UseFormReturn<T, any, undefined>;
  fieldName: Path<T>;
  fieldLabel?: string;
  fieldPlaceholder?: string;
  fieldDescription?: string | React.ReactNode;
  layout?: keyof typeof layouts;
  required?: boolean;
  floating?: boolean;
  disabled?: boolean;
  type?: HTMLInputElement['type'];
  validateEarly?: boolean;
  validateBlur?: boolean;
  pattern?: string;
  error?: boolean;
  serializeInput?: (value: any) => any;
  inputClassName?: string;
  className?: string;
};

const layouts = {
  default: {
    formItemClasses: 'gap-4 space-y-2',
    formInputClasses: 'col-span-2',
    formMessageClasses: 'col-span-3',
    formLabelClasses: 'space-x-2 font-thin',
  },
  medium: {
    formItemClasses: '',
    formInputClasses: 'h-14 w-full text-base rounded-lg',
    formMessageClasses: '',
    formLabelClasses: '',
  },
  big: {
    formItemClasses: '',
    formInputClasses: 'h-16 w-full text-base',
    formMessageClasses: '',
    formLabelClasses: '',
  },
};

export const FormInput = <T extends FieldValues>({
  form,
  fieldName,
  fieldLabel,
  fieldDescription,
  fieldPlaceholder,
  layout = 'default',
  required = false,
  floating = false,
  disabled = false,
  type = 'text',
  validateEarly = false,
  validateBlur = false,
  pattern,
  error = false,
  serializeInput,
  inputClassName,
  className,
}: InputProps<T>) => {
  const inputProps = (field: ControllerRenderProps<any, any>) => ({
    ...field,
    onChange: (value: any) => {
      if (serializeInput) value = serializeInput(value.target.value);

      // Handle empty string for number inputs
      if (type === 'number' && value.target.value === '') {
        field.onChange(null);
      } else {
        field.onChange(value);
      }
      validateEarly ||
        (form.getFieldState(fieldName).error && form.trigger(fieldName));
    },
    onBlur: () => {
      validateBlur && form.trigger(fieldName);
    },
    value: field.value ?? '', // Use empty string for null/undefined values
    placeholder: fieldPlaceholder || '',
    className: cn(
      layouts[layout].formInputClasses,
      'border-transparent',
      {
        'border-brand-red': error,
      },
      inputClassName,
    ),
    'data-pw': field.name,
    type: type,
  });

  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className={cn(layouts[layout].formItemClasses, className)}>
          {!floating && fieldLabel && (
            <FormLabel
              className={cn(required && layouts[layout].formLabelClasses)}
            >
              <span>{fieldLabel}</span> {required && <span>*</span>}
            </FormLabel>
          )}
          <FormControl>
            {floating ? (
              <InputWithInternalLabel
                {...inputProps(field)}
                disabled={disabled}
                pattern={pattern}
                label={fieldLabel}
              />
            ) : (
              <Input
                {...inputProps(field)}
                disabled={disabled}
                pattern={pattern}
              />
            )}
          </FormControl>
          {fieldDescription && (
            <FormDescription>{fieldDescription}</FormDescription>
          )}
          <FormMessage
            className={layouts[layout].formMessageClasses}
            t={useTranslations()}
          />
        </FormItem>
      )}
    />
  );
};
