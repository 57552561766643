import { Checkbox } from '@/components/ui/checkbox';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import type { JSX } from 'react';
import {
  type FieldValues,
  type Path,
  type UseFormReturn,
} from 'react-hook-form';

const layouts = {
  default: {
    formItemClasses: 'space-y-2',
    formCheckboxClasses:
      'border-muted-foreground ring-offset-background focus-visible:ring-ring data-[state=checked]:bg-brand-green-light data-[state=checked]:text-primary-foreground data-[state=checked]:border-transparent peer h-6 w-6 shrink-0 rounded-[4.5px] border focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    formMessageClasses: '',
    formFieldLabelClasses: 'text-md',
  },
  bigWhite: {
    formItemClasses: 'flex items-center space-x-3 space-y-0',
    formCheckboxClasses: 'border-0 bg-white w-5 h-5',
    formMessageClasses: '',
    formFieldLabelClasses: '',
  },
};
export const FormCheckbox = <T extends FieldValues>({
  form,
  fieldName,
  fieldLabel,
  fieldDescription,
  layout = 'default',
  required = false,
}: {
  form: UseFormReturn<T, any, undefined>;
  fieldName: Path<T>;
  fieldLabel?: string | JSX.Element;
  fieldDescription?: string | JSX.Element;
  layout?: keyof typeof layouts;
  required?: boolean;
}) => {
  const t = useTranslations();
  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }) => (
        <>
          <FormItem className={layouts[layout].formItemClasses}>
            <div className='flex items-start gap-2'>
              <FormControl>
                <Checkbox
                  {...field}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className={layouts[layout].formCheckboxClasses}
                  data-pw={`${field.name}-checkbox`}
                />
              </FormControl>
              <div>
                {fieldLabel && (
                  <FormLabel
                    className={cn(
                      { 'space-x-2': required },
                      layouts[layout].formFieldLabelClasses,
                    )}
                    data-pw={field.name}
                  >
                    <span>{fieldLabel}</span>
                    {required && <span>*</span>}
                  </FormLabel>
                )}
                {fieldDescription && (
                  <FormDescription>{fieldDescription}</FormDescription>
                )}
              </div>
            </div>
          </FormItem>
          <FormMessage className={layouts[layout].formMessageClasses} t={t} />
        </>
      )}
    />
  );
};
