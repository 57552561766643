import X from '@/assets/icons/x.svg';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@repo/ui/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@repo/ui/components/ui/select';
import type { ReactNode } from 'react';
import {
  type FieldValues,
  type Path,
  type UseFormReturn,
} from 'react-hook-form';

const layouts = {
  default: {
    formItemClasses: 'col-span-3 grid grid-cols-3 items-center gap-4 space-y-0',
    selectTriggerClasses: 'col-span-2 w-full font-medium',
    formMessageClasses: 'col-span-3',
  },
  medium: {
    formItemClasses: '',
    selectTriggerClasses: 'h-14 w-full text-base font-medium',
    formMessageClasses: '',
  },
  big: {
    formItemClasses: '',
    selectTriggerClasses: 'h-14 md:h-16 w-full text-base font-medium',
    formMessageClasses: '',
  },
};

export const FormSelect = <T extends FieldValues>({
  form,
  fieldName,
  fieldLabel,
  fieldPlaceholder,
  fieldOptions,
  required = false,
  layout = 'default',
  onChange,
  clearable = true,
}: {
  form: UseFormReturn<T, any, undefined>;
  fieldName: Path<T>;
  fieldLabel?: string;
  fieldPlaceholder?: string;
  fieldOptions: { label: string | ReactNode; value: string }[];
  required?: boolean;
  layout?: keyof typeof layouts;
  onChange?: () => void;
  clearable?: boolean;
}) => {
  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }: { field: any }) => (
        <FormItem className={cn('relative', layouts[layout].formItemClasses)}>
          {fieldLabel && (
            <FormLabel className={cn('space-x-2 font-semibold')}>
              <span>{fieldLabel}</span>
              {required && <span>*</span>}
            </FormLabel>
          )}
          <Select
            value={field.value}
            onValueChange={(value: string) => {
              field.onChange(value);
              onChange?.();
            }}
          >
            <FormControl>
              <SelectTrigger className={layouts[layout].selectTriggerClasses}>
                <SelectValue placeholder={fieldPlaceholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent id={fieldName}>
              {fieldOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
            {field.value && clearable && (
              <Button
                type='button'
                variant='ghost'
                size='icon'
                className='absolute right-0.5 top-0.5 bg-white p-2 hover:bg-white md:top-1'
                onClick={() => {
                  field.onChange('');
                  onChange?.();
                }}
              >
                <X className='h-4 w-4' />
              </Button>
            )}
          </Select>
          <FormMessage className={layouts[layout].formMessageClasses} />
        </FormItem>
      )}
    />
  );
};
