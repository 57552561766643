'use client';

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

type ExtendedCalendarProps = {
  vertical?: boolean;
  fixedWeeks?: boolean;
};

function CalendarNew({
  className,
  classNames,
  showOutsideDays = true,
  vertical = false,
  fixedWeeks = true,
  ...props
}: CalendarProps & ExtendedCalendarProps) {
  const size = 'w-full h-[42px]';
  const fixedSize = 'w-[42px] h-[42px]';
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      fixedWeeks={fixedWeeks}
      className={cn('p-0', className)}
      modifiers={{ currentSelected: props.selected || [] }}
      modifiersClassNames={{
        currentSelected: 'bg-brand-green-light!',
      }}
      classNames={{
        months: cn('flex', {
          'flex-col space-y-4': vertical,
          'flex-row items-start space-x-4 space-y-4 sm:space-y-0': !vertical,
        }),
        month: 'space-y-4 w-full flex flex-col justify-center',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'ghost', size: 'adjusted' }),
          'bg-transparent p-0 opacity-50 hover:opacity-100',
          fixedSize,
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse',
        head_row: 'grid place-items-center grid-cols-7 w-full mb-2',
        head_cell:
          'text-muted-foreground rounded-lg w-9 sm:w-10 font-normal text-[0.8rem]',
        row: 'w-full grid grid-cols-7 place-items-center py-1 gap-1',
        cell: cn(
          'text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-transparent [&:has([aria-selected])]:bg-transparent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative',
          size,
        ),
        day: cn(
          buttonVariants({ variant: 'ghost', size: 'adjusted' }),
          'p-0 font-normal aria-selected:opacity-100 bg-brand-green-light text-white relative z-0 max-w-[42px]',
          size,
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary! text-primary-foreground! hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_outside:
          'day-outside text-brand-green2! opacity-50 aria-selected:bg-brand-primary aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'bg-transparent text-brand-green2! opacity-50!',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        tbody: '',
        ...classNames,
      }}
      components={{
        IconLeft: () => <IconChevronLeft stroke={1.25} />,
        IconRight: () => <IconChevronRight stroke={1.25} />,
      }}
      {...props}
    />
  );
}
CalendarNew.displayName = 'CalendarNew';

export { CalendarNew };
