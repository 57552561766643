import { CalendarNew } from '@/components/ui/calendar-new';
import { cn } from '@/lib/utils';
import { Button, buttonVariants } from '@repo/ui/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@repo/ui/components/ui/form';
import { addDays, startOfDay } from 'date-fns';
import { enUS, pl } from 'date-fns/locale';
import { useLocale, useTranslations } from 'next-intl';
import {
  type Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { type StyledElement } from 'react-day-picker';
import {
  type ControllerRenderProps,
  type UseFormReturn,
} from 'react-hook-form';

type Props = {
  form: UseFormReturn<any, any, undefined>;
  fieldName: string;
  fieldNameDaysToSelect: string;
  daysToSelect?: number;
  disabledDays?: Date[];
  disableSaturdays?: boolean;
  disableSundays?: boolean;
  firstPossibleOrderDay?: Date;
  isDisabledDate?: (date: Date) => boolean;
  showMessage?: boolean;
  calendarClasses?: Partial<StyledElement<string>>;
  showClearButton?: boolean;
  showOutsideDays?: boolean;
  fixedWeeks?: boolean;
  disableAutoNextDays?: boolean;
  firstPossibleChangeDay?: Date;
};

export type CalendarWithMonthsHandle = {
  handleClear: () => void;
};

const CalendarWithMonths = ({
  months,
  field,
  vertical = false,
  locale = 'pl',
  handleSelection,
  isDisabledDate,
  customKey,
  fixedWeeks,
  defaultMonth,
  calendarClasses,
  showOutsideDays = true,
}: {
  months: number;
  field: ControllerRenderProps<any, string>;
  vertical?: boolean;
  locale?: string;
  handleSelection: (dates?: Date[]) => void;
  isDisabledDate: (date: Date) => boolean;
  customKey: string;
  fixedWeeks?: boolean;
  defaultMonth?: Date;
  calendarClasses?: Partial<StyledElement<string>>;
  showOutsideDays?: boolean;
}) => {
  const getCalendarLocale = () => {
    switch (locale) {
      case 'pl':
        return pl;
      case 'en':
        return enUS;
      default:
        return pl;
    }
  };

  return (
    <FormControl>
      <CalendarNew
        key={customKey}
        // mode 'multiple' is hardcoded because we want to allow selecting multiple dates
        // there is only one use of calendar form in our application which is date selection
        // for orders - it will always be multiple
        mode='multiple'
        vertical={vertical}
        locale={getCalendarLocale()}
        selected={field.value}
        onSelect={handleSelection}
        disabled={isDisabledDate}
        numberOfMonths={months}
        classNames={{
          head_row:
            'grid place-items-center grid-cols-7 w-full mb-2 border-t pt-2',
          day: cn(
            buttonVariants({ variant: 'ghost', size: 'adjusted' }),
            'p-0 font-normal aria-selected:opacity-100 bg-background text-brand-black relative z-0 max-w-[42px] w-full h-[42px]',
          ),
          day_selected:
            'bg-brand-green-light text-white! hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
          ...calendarClasses,
        }}
        fixedWeeks={fixedWeeks}
        defaultMonth={defaultMonth}
        showOutsideDays={showOutsideDays}
      />
    </FormControl>
  );
};

export const FormDatePicker = forwardRef(
  (
    {
      form,
      fieldName,
      fieldNameDaysToSelect,
      isDisabledDate,
      daysToSelect,
      disableSaturdays,
      disableSundays,
      firstPossibleOrderDay,
      disabledDays,
      showMessage = true,
      showClearButton = true,
      calendarClasses,
      showOutsideDays = true,
      fixedWeeks,
      disableAutoNextDays = false,
      firstPossibleChangeDay,
    }: Props,
    ref: Ref<CalendarWithMonthsHandle>,
  ) => {
    const t = useTranslations();
    const [selectedDates, setSelectedDates] = useState<Date[]>(
      form.getValues(fieldName) || [],
    );

    const [touched, setTouched] = useState(false);

    const locale = useLocale();

    useImperativeHandle(ref, () => ({
      handleClear,
    }));

    // if selectedDates change, update the form value
    useEffect(() => {
      form.setValue(fieldName, selectedDates, {
        shouldDirty: true,
        shouldTouch: true,
      });
      if (touched) form.trigger(fieldName);
    }, [selectedDates, daysToSelect]);

    // If user changes the disabled days configuration, remove the disabled dates from the selected dates
    useEffect(() => {
      const filtered = filterSelectedDates(selectedDates);
      setSelectedDates(filtered);
      if (selectedDates.length > 0 && filtered.length > 0)
        form.setValue(fieldNameDaysToSelect, filtered.length);
    }, [disableSaturdays, disableSundays]);

    // If user changes the daysToSelect
    useEffect(() => {
      if (!daysToSelect) return;
      if (selectedDates.length > daysToSelect) {
        const count = selectedDates.length - daysToSelect;
        setSelectedDates(
          selectedDates.filter((date) =>
            findFirstDays(selectedDates, count).includes(date),
          ),
        );
      }
      if (
        selectedDates.length &&
        selectedDates.length < daysToSelect &&
        !disableAutoNextDays
      ) {
        const addedDates = findNextAvailableDatesArray(
          findLastDay(selectedDates),
          daysToSelect - selectedDates.length,
        );
        setSelectedDates([...selectedDates, ...addedDates]);
      }
    }, [selectedDates, daysToSelect]);

    const filterSelectedDates = (dates: Date[]) => {
      if (firstPossibleChangeDay) {
        return dates.filter((date) => {
          return (
            !isDateDisabledChecker(date) ||
            startOfDay(date) <= startOfDay(firstPossibleChangeDay)
          );
        });
      }

      return dates.filter((date) => !isDateDisabledChecker(date));
    };

    // check if the date is disabled
    const isDateDisabledChecker = (day: Date) => {
      if (isDisabledDate) return isDisabledDate(day);

      if (disableSaturdays && day.getDay() === 6) return true;
      if (disableSundays && day.getDay() === 0) return true;
      if (
        disabledDays &&
        disabledDays.find((date) => date.toDateString() === day.toDateString())
      )
        return true;
      if (
        firstPossibleOrderDay &&
        startOfDay(day) < startOfDay(firstPossibleOrderDay)
      )
        return true;

      if (
        firstPossibleChangeDay &&
        startOfDay(day) < startOfDay(firstPossibleChangeDay)
      )
        return true;

      return false;
    };

    // find the next available date
    const findNextAvailableDate = (date: Date): Date => {
      const nextDay = addDays(date, 1);
      if (!isDateDisabledChecker(nextDay)) return nextDay;
      return findNextAvailableDate(nextDay);
    };

    const findNextAvailableDatesArray = (date: Date, count: number) => {
      const nextDays = [];
      let nextDay = date;
      for (let i = 0; i < count; i++) {
        nextDay = findNextAvailableDate(nextDay);
        nextDays.push(nextDay);
      }
      return nextDays;
    };

    // find the last selected date - selectedDays can be unordered
    const findLastDay = (dates: Date[]) => {
      const lastDate = dates.reduce((a, b) => (a > b ? a : b));
      return lastDate;
    };

    // find the last [count] days from the selected dates
    const findFirstDays = (dates: Date[], count: number) => {
      const lastDates = dates
        .sort((a, b) => a.getTime() - b.getTime())
        .slice(0, dates.length - count);
      return lastDates;
    };

    // handle the first selection - user didn't select any date yet and it's the first one
    const firstSelection = (date: Date) => {
      if (!daysToSelect) daysToSelect = 1;

      // create an array of [daysToSelect] dates from the first selected date

      let addedDates = Array.from({ length: daysToSelect - 1 }, (_, i) =>
        addDays(date, i + 1),
      );

      const disabled = addedDates.filter((date) => isDateDisabledChecker(date));

      // remove the disabled dates from the array
      addedDates = addedDates.filter((date) => !disabled.includes(date));

      // add the next available dates to the array
      disabled.forEach(() => {
        let lastDate = findLastDay(addedDates);
        addedDates.push(findNextAvailableDate(lastDate));
        lastDate = findLastDay(addedDates);
      });

      setSelectedDates([date, ...addedDates]);
    };

    // handle the selection of the dates
    const handleSelection = (dates?: Date[]) => {
      if (!dates) return;
      setTouched(true);

      // first selection
      if (selectedDates.length === 0 && dates.length > 0) {
        firstSelection(dates[0] as Date);
      } else {
        setSelectedDates(dates || []);
        if (dates.length !== daysToSelect) {
          if (dates.length === 0) {
            form.resetField(fieldNameDaysToSelect);
          } else {
            form.setValue(fieldNameDaysToSelect, dates.length);
          }
          form.trigger(fieldNameDaysToSelect);
          form.trigger(fieldName);
        }
      }
    };

    // clear all of the selected dates
    const handleClear = () => {
      setSelectedDates([]);
    };

    return (
      <FormField
        control={form.control}
        name={fieldName}
        render={({ field }) => (
          <>
            {showClearButton && (
              <div className='flex items-center gap-4 md:flex-row'>
                <Button
                  variant='link'
                  size='sm'
                  onClick={handleClear}
                  type='button'
                  data-pw='clear-days'
                  className='m-0 p-0 text-base'
                >
                  {t('cart.calendar.clear')}
                </Button>
              </div>
            )}
            {showMessage && <FormMessage t={t} />}
            <FormItem className='bg-background flex h-full w-full items-center gap-4 rounded-lg px-2 py-1 md:px-4 md:py-4'>
              <div className='hidden w-full lg:grid'>
                <CalendarWithMonths
                  months={2}
                  field={field}
                  locale={locale}
                  handleSelection={handleSelection}
                  isDisabledDate={isDateDisabledChecker}
                  customKey='calendar-desktop'
                  fixedWeeks={fixedWeeks}
                  defaultMonth={firstPossibleOrderDay}
                  calendarClasses={calendarClasses}
                  showOutsideDays={showOutsideDays}
                />
              </div>
              <div className='grid w-full lg:hidden'>
                <CalendarWithMonths
                  months={1}
                  field={field}
                  vertical
                  handleSelection={handleSelection}
                  isDisabledDate={isDateDisabledChecker}
                  customKey='calendar-mobile'
                  fixedWeeks={fixedWeeks}
                  defaultMonth={firstPossibleOrderDay}
                  calendarClasses={calendarClasses}
                  showOutsideDays={showOutsideDays}
                />
              </div>
            </FormItem>
          </>
        )}
      />
    );
  },
);
